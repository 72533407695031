import React, { useEffect, Suspense, lazy } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';

// Preload critical routes
import Home from './pages/Home';
import Company from './pages/Company';

// Lazy load non-critical routes
const Programs = lazy(() => import('./pages/Programs'));
const Services = lazy(() => import('./pages/Services'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Error = lazy(() => import('./pages/Error'));
const Connect = lazy(() => import('./pages/Connect'));
const BlogPost = lazy(() => import('./components/BlogPost'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const Faq = lazy(() => import('./components/faq'));

function AppRoutes() {
  const location = useLocation();

  // Google Analytics page view tracking
  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-4HXPXEP4DQ', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  // Preload frequently accessed pages
  useEffect(() => {
    import('./pages/Programs');
    import('./pages/Services');
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Company />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/services" element={<Services showAll={true} />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/post" element={<BlogPost />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
