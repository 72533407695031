import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './pages/ScrollToTop';
import AppRoutes from './AppRoutes'; // Create a new component for routes
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <ScrollToTop />
      <NavBar />
      <AppRoutes /> {/* Moved routes logic to a separate component */}
      <Footer />
    </HelmetProvider>
  );
}

export default App;
