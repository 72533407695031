import React from 'react';
import { Helmet } from 'react-helmet';

// Icons
import { SiSemanticscholar } from 'react-icons/si';
import { BiUserVoice } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { LiaUniversitySolid, LiaHandsHelpingSolid } from 'react-icons/lia';
import { MdOutlineAssignment } from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { TbProgressCheck } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';

const WhyUs = () => {
    return (
        <div>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Fly Vision | Study Visa & Visitor Visa Consultants in Mansa</title>
                <meta
                    name="description"
                    content="Fly Vision Consultants in Mansa provide expert services for Study Visa, Visitor Visa, and more. Choose us for affordable and transparent visa assistance."
                />
                <meta
                    name="keywords"
                    content="Fly Vision, Fly Vision Mansa, Study Visa Consultants, Visitor Visa Consultants, Visa Assistance in Mansa, Fly Vision Punjab, Best Visa Consultants in Mansa"
                />
                {/* Open Graph Tags for Social Media */}
                <meta property="og:title" content="Fly Vision | Study Visa & Visitor Visa Consultants in Mansa" />
                <meta
                    property="og:description"
                    content="Fly Vision provides expert guidance for Study Visa and Visitor Visa applications in Mansa. Trusted consultants for visa assistance in Punjab."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://flyvisionmansa.com" />
                <meta property="og:image" content="https://flyvisionmansa.com/assets/logo.png" />
            </Helmet>

            {/* Schema Markup (Structured Data) */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Fly Vision",
                    "url": "https://flyvisionmansa.com",
                    "logo": "https://flyvisionmansa.com/assets/logo.png",
                    "sameAs": [
                        "https://www.facebook.com/flyvision",
                        "https://www.instagram.com/flyvision"
                    ],
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "+91-9876543210",
                        "contactType": "customer service"
                    },
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Main Road, Mansa",
                        "addressLocality": "Mansa",
                        "addressRegion": "Punjab",
                        "postalCode": "151505",
                        "addressCountry": "IN"
                    }
                })}
            </script>

            {/* Why Us Section */}
            <div className="py-12">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-gray-900">Why Choose Fly Vision?</h1>
                    <p className="text-lg text-gray-600 mt-4">
                        Trusted Study Visa & Visitor Visa Consultants in Mansa
                    </p>
                </div>

                {/* Services Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Expertise */}
                    <div className="p-8 border rounded-lg shadow-md">
                        <div
                            className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 shadow-md"
                            aria-label="Expertise Icon"
                        >
                            <TiTickOutline className="w-8 h-8 text-gray-700" />
                        </div>
                        <h2 className="text-xl font-semibold text-gray-800">Expertise</h2>
                        <p className="text-sm text-gray-600 mt-2">
                            Our team consists of experienced professionals with in-depth knowledge of study visa and visitor visa application processes in multiple countries.
                        </p>
                    </div>

                    {/* Customized Solutions */}
                    <div className="p-8 border rounded-lg shadow-md">
                        <div
                            className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 shadow-md"
                            aria-label="Customized Solutions Icon"
                        >
                            <AiOutlineSolution className="w-10 h-10 text-gray-700" />
                        </div>
                        <h2 className="text-xl font-semibold text-gray-800">Customized Solutions</h2>
                        <p className="text-sm text-gray-600 mt-2">
                            We offer personalized solutions based on individual student profiles to ensure successful visa applications.
                        </p>
                    </div>
                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TbProgressCheck className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Transparency</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We maintain complete transparency throughout the process, and our students are regularly updated about the progress of their application.
                        </p>
                    </div>
                </div>
                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <RiHandCoinLine className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Affordable Services</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We believe that everyone deserves quality education and we offer the lowest fee packages.
                        </p>
                    </div>
                </div>
                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaUniversitySolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">University Selection</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We help students select the right course, college or university, and country based on their academic profile, interests, and budget.
                        </p>
                    </div>
                </div>

                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaHandsHelpingSolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Assistance Guidance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We assist students in the entire application process, including filling out application forms, writing SOPs, essays, and letters of recommendation etc.
                        </p>
                    </div>

                </div>

                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <MdOutlineAssignment className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Visa Assistance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We guide students through the visa process, including preparing the required documents, scheduling visa workshops, and providing guidance on visa interview preparation.
                        </p>
                    </div>
                </div>

                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <BiUserVoice className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Pre-Departure Briefing</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We provide students with a comprehensive briefing on what to expect once they reach their destination country, including information on accommodation, travel, and orientation.
                        </p>
                    </div>
                </div>

                <div className="p-8 border rounded-lg shadow-md">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <SiSemanticscholar className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Scholarship Assistance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We provide information on available scholarships and assist students in applying.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default WhyUs;
